<template>
  <div>
    <headers />
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">企業一覧</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card-box">
          <list-table :viewParams="viewParams" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/ListTable.vue'
import Headers from '@/components/Headers.vue'

export default {
  name: 'CorpList',
  components: {
    ListTable,
    Headers
  },
  data() {
    return {
      viewParams: {
        name: 'corp10',
        apiUrl: '/v1/corps10/',
        articleView: 'CorpArticle1',
        articleParamId: 'ID',
        dataType:10,
      }
    }
  }
}
</script>